import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";

import content2 from "./../../assets/content2.webp";

const SPM = () => {
  return (
    <Paper sx={{ bgcolor: "background.lightBlue" }} variant="elevation" className="noRadius">
      <Stack direction={{ xs: "column", sm: "row" }} height={"100%"} alignItems={"center"} p={4}>
        <Box
          component="img"
          src={content2}
          sx={{
            width: { xs: "100%", sm: "40%" },
            borderRadius: 10,
          }}
          m={5}
        />
        <Stack
          ml={1}
          mr={{ xs: 1, sm: 10 }}
          alignItems={"flex-start"}
          my={"auto"}
          spacing={3}
          className="noMargin textkontenSPM"
        >
          <Typography
            className="fontW700 fontJakarta"
            display="flex"
            variant="h4"
            color="white"
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}

          >
            Ukur Kemampuanmu dengan Standard Progressive Matrics (SPM) Test!
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            variant="body2"
            color="white"
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
            className="fontS18 fontJakarta"
          >
            Standard Progressive Matrices (SPM) Test digunakan untuk mengukur
            kemampuan seseorang dalam mengerti dan melihat hubungan antara
            bagian-bagian gambar yang disajikan serta mengembangkan pola
            berpikir yang sistematis.
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{
                bgcolor: "orange",
                textTransform: "none",
              }}
              className='fontJakarta'
            >
              Coming Soon
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};
export default SPM;
