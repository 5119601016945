import { Typography, Container, Stack, IconButton, Grid } from "@mui/material";
import logo from "./../assets/cl.webp";
import ig from "./../assets/ig.png";
import wa from "./../assets/wa.png";
import youtube from "./../assets/youtube.png";
import { Box } from "@mui/system";
import React from "react";

const Footer = () => {
  return (
    <div>
      <Grid >
        <Stack className="footero">
          <Box
            component="img"
            src={logo}
            sx={{ width: { xs: "30%", lg: "20%" } }}
          ></Box>
          <Typography variant="body2" className="fontJakarta colorTextLight">
            Di Future Link, kami percaya untuk membuka potensi penuh setiap
            individu. Teknologi kami memberikan wawasan tentang kemampuan dan
            kekuatan bawaan setiap orang.
          </Typography>
          <a href="https://maps.app.goo.gl/4YKXWKqXb2dSYh3y5" target="_blank" style={{textDecoration: 'none', color: 'black'}}>
            <b className="colorText">Komplek Kavling DKI, Jl. Purwa Utama Blok RI 37, Jagakarsa, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta, 12530.</b>
          </a>
          <Stack direction="row" spacing={2}>
            <IconButton
              onClick={() =>
                window.open("https://www.instagram.com/futurelink.id/")
              }
            >
              <Box component="img" src={ig} maxWidth={45}></Box>
            </IconButton>
            <IconButton
              aria-label="yt"
              onClick={() =>
                window.open("https://www.youtube.com/@futurelink_id")
              }
            >
              <Box component="img" src={youtube} maxWidth={45}></Box>
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid container justifyContent="center" alignItems="center" mb={5}>
        <Typography variant="body2">© 2024, All Rights Reserved</Typography>
      </Grid>
    </div>
  );
};

export default Footer;
