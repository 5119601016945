import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";
import mba2 from "./../../assets/mbakbingung.webp";

const Contact = () => {
  return (
    <Paper
      sx={{
        bgcolor: "background.darkBlue",
        borderRadius: 0,
        height: "100%",
        p: 15,
        px: 5,
      }}
      align="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} height={"100%"} alignItems={"center"}>
        <Box
          component="img"
          src={mba2}
          sx={{
            width: { xs: "100%", sm: "40%" },
            borderRadius: "10px",
          }}
          m={5}
        />
        <Stack
          ml={1}
          mr={{ xs: 1, sm: 10 }}
          alignItems={"flex-start"}
          my={"auto"}
          spacing={3}
          className="noMargin textkontenSPM"
        >
          <Typography variant="h4" color={"white"} align="left" className="fontJakarta fontS40 fontW700">
            Masih bingung dan pengen tau lebih lanjut?
          </Typography>
          <Typography variant="body2" color={"white"} align="left" className="fontJakarta" style={{marginBottom: '24px'}}>
            Hubungi kami jika kamu masih ingin tahu lebih lanjut tentang test
            sidik jari dari future link, kami selalu ada untuk melayani semua
            pertanyaan kalian! 😉
          </Typography>
          <Button
            variant="contained"
            sx={{
              width: 150,
              bgcolor: "orange",
              textTransform: "none",
            }}
            onClick={() => {
              window.open("https://wa.me/6285960206799");
            }}
            className="fontJakarta"
            style={{padding: '10px 0px'}}
          >
            Kontak Kami
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Contact;
