import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";
import join from "./../../assets/join.svg";

const Franchise = () => {
  return (
    <Paper
      sx={{
        backgroundImage: `url(${join})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: 0,
        height: "100%",
        py: 15,
        px: { sm: 5 },
      }}
      align={!utils.CheckPhone() ? "center" : "left"}
      // align={{ xs: "center", sm: "center" }}
    >
      <Stack spacing={2} className="widthPartner">
        <Typography variant="h3" color={"white"} className="fontJakarta fontS40 fontW700">
          Ingin Membuka Kemitraan dengan Kami?
        </Typography>
        <Typography variant="body2" color={"white"} className="fontJakarta font18">
          Kami menyediakan kemitraan agar kamu dapat memaksimalkan bisnismu.
          Tidak terbatas pada perusahaan saja, kami membuka peluang kemitraan
          bagi organisasi maupun personal.
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: "max-content",
            bgcolor: "orange",
            textTransform: "none",
          }}
          onClick={() => {
            window.open("https://wa.me/6285960206799");
          }}
          className="fontJakarta buttonPartner"
          style={{padding: '12px 32px'}}
        >
          Hubungi Kami untuk Kemitraan!
        </Button>
      </Stack>
    </Paper>
  );
};

export default Franchise;
