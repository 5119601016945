import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";
import icon from "./../../assets/dualperson.svg";

const WhyBox = (props) => {
  return (
    // <Container>
    <Paper mt={10} sx={{ width: { sm: "50%", lg: "30%" }, borderRadius: 5 }}>
      <Stack m={5} spacing={1} alignItems="center" mt={-3}>
        <div style={{background: 'linear-gradient(135deg, #FF7518 0%, #FF954E 99.99%, #FFA01B 100%)', padding: '12px', borderRadius: '12px', display: 'flex'}}>
        <Box
          component="img"
          src={icon}
          style={{width: '24px'}}
          // sx={{ width: { xs: "30%", lg: "30%" } }}
        />
        </div>

        <Typography display="flex" variant="h6" align="center" style={{marginTop: '20px'}}>
          <b style={{color:"#374151", lineHeight: '24px'}}>{props.title}</b>
        </Typography>
        <Typography display="flex" align="center" variant="body3" style={{color: '#262626BF', fontSize: '14px'}}>
          {props.subtitle}
        </Typography>
      </Stack>
    </Paper>
    // </Container>
  );
};

const Why = () => {
  return (
    <Paper sx={{ bgcolor: "background.lightBlue" }} variant="elevation" className="noRadius">
      <Stack spacing={5} alignItems="center" p={5}>
        <Typography className="fontJakarta fontW700"  display="flex" variant="h4" color="white" style={{margin: '50px 0px'}}>
          Mengapa Memilih Future Link?
        </Typography>
        <>
          {!utils.CheckPhone() ? (
            <>
              {/* <BearCarousel data={bearSlideItemData} slidesPerView={1} /> */}
              <WhyBox
                title="Test yang Efisien"
                subtitle="Kami menawarkan proses pengujian yang efisien dengan hasil analisis yang cepat dan akurat."
              />
              <WhyBox
                title="Otomatisasi"
                subtitle="Semua proses berjalan secara otomatis menggunakan  Machine Learning."
              />
              <WhyBox
                title="Fitur yang Lengkap"
                subtitle="Future Link memiliki fitur laporan yang mencakup berbagai konten."
              />
              <WhyBox
                title="Hasil Berdasarkan Riset"
                subtitle="Future Link menampilkan analisis sidik jari yang dikembangkan berdasarkan jurnal dan penelitian teknologi terbaru."
              />
              <WhyBox
                title="Didukung oleh Konselor Berpengalaman"
                subtitle="Future Link memiliki konselor berpengalaman yang siap membantu kamu dalam membimbing sesuai dengan hasil laporan kamu."
              />
              <WhyBox
                title="Keamanan Data Privasi"
                subtitle="Kami menjadi keamanan bagi seluruh pengguna Future Link. Kamu tidak perlu khawatir akan keamanan data kamu ☺️"
              />
            </>
          ) : (
            <>
              <Stack direction={"row"} spacing={5} justifyContent="center">
                <WhyBox
                  title="Test yang Efisien"
                  subtitle="Kami menawarkan proses pengujian yang efisien dengan hasil analisis yang cepat dan akurat."
                />
                <WhyBox
                  title="Otomatisasi"
                  subtitle="Semua proses berjalan secara otomatis menggunakan  Machine Learning."
                />
                <WhyBox
                  title="Fitur yang Lengkap"
                  subtitle="Future Link memiliki fitur laporan yang mencakup berbagai konten."
                />
              </Stack>
              <Stack direction={"row"} spacing={5} justifyContent="center">
                <WhyBox
                  title="Hasil Berdasarkan Riset"
                  subtitle="Future Link menampilkan analisis sidik jari yang dikembangkan berdasarkan jurnal dan penelitian teknologi terbaru."
                />
                <WhyBox
                  title="Didukung oleh Konselor Berpengalaman"
                  subtitle="Future Link memiliki konselor berpengalaman yang siap membantu kamu dalam membimbing sesuai dengan hasil laporan kamu."
                />
                <WhyBox
                  title="Keamanan Data Privasi"
                  subtitle="Kami menjadi keamanan bagi seluruh pengguna Future Link. Kamu tidak perlu khawatir akan keamanan data kamu ☺️"
                />
              </Stack>
            </>
          )}
        </>
      </Stack>
    </Paper>
  );
};

export default Why;
