import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";
import testi1 from "./../../assets/testi1.png";
import testi2 from "./../../assets/testi2.png";
import testi3 from "./../../assets/testi3.png";


const reviews = [
  {
    id: 1,
    star: 5,
    name: "Gaitsha Azzahra D",
    work: "Mahasiswi",
    review:
      "Terimakasih kakk, future link sangat membantu aku yang hampir bingung mengenali potensi akan jurusan untuk kuliah,laporannya sangat kompleks.",
    photo: testi1,
  },
  {
    id: 2,
    star: 4.5,
    name: "Yoga Bramantya",
    work: "Pegawai",
    review:
      "Kini saya dapat lebih menata pekerjaan yang saya lakukan dan lebih mudah berinteraksi dengan rekan kerja.",
    photo: testi2,
  },
  {
    id: 3,
    star: 4.5,
    name: "Prabu Dzidni Al Falaq",
    work: "Siswa SMP",
    review:
      "Terimakasih future link udah bantu aku mengenali potensi yang aku punya, selama ini aku kesulitan belajar dari segi akademik, akhirnya aku tau kalau bakat dan potensi ku memang gak ada disana.",
    photo: testi3,
  },
];

const Testimony = () => {
  const carouselMainRef = useRef(null);
  const [carouselMainController, setMainController] = useState();
  const [currLevel, setCurrLevel] = useState();
  const bearSlideItemData: TBearSlideItemDataList = reviews.map((row) => {
    return {
      key: row.id,
      children: (
        <BearSlideCard>
          <Paper
            sx={{ bgcolor: "white", p: 5, borderRadius: 5 }}
            variant="plain"
            alignContent="center"
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Box
                component="img"
                src={row.photo}
                sx={{
                  width: { xs: "10%", lg: "10%" },
                  borderRadius: 10,
                }}
              />
              <Stack align={"left"}>
                <Rating
                  defaultValue={row.star}
                  precision={0.5}
                  readOnly={true}
                  size="large"
                />
                <Typography noWrap="true" variant="h5" color={"black"}>
                  <strong>{row.name}</strong>
                </Typography>
                <Typography
                  noWrap="true"
                  level="title-lg"
                  variant="body1"
                  color={"gray"}
                  fontSize="sx"
                >
                  {row.work}
                </Typography>
                <Typography level="body-xs" variant="subtitle2" color={"black"}>
                  {row.review}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </BearSlideCard>
      ),
    };
  });

  useEffect(() => {
    carouselMainController?.slideToPage(5, false);
  }, [carouselMainController]);
  const mainEffectFn: TMoveEffectFn = useCallback((percentageInfo) => {
    const transformY = 40;
    return {
      transform: `translate(0px, ${
        -transformY * (percentageInfo.calcPercentage - 1)
      }px)`,
    };
  }, []);
  const handleSlideChange = useCallback((state) => {
    const curr = reviews.find(
      (row, index) => index === state.virtual.activeIndex
    );
    if (curr) {
      setCurrLevel({ lv: curr.lv, count: curr.count });
    }
  }, []);

  const ipad = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Paper
      sx={{
        bgcolor: "background.darkBlue",
        borderRadius: 0,
        height: { xs: 300, sm: 400 },
        py: 5,
      }}
      align="center"
    >
      <Typography noWrap="true" variant="h4" color={"white"} style={{marginBottom: '42px'}} className="fontW700 fontJakarta">
        Apa kata mereka
      </Typography>
      <BearCarousel
        ref={carouselMainRef}
        onSlideChange={handleSlideChange}
        setController={setMainController}
        data={bearSlideItemData}
        slidesPerView={!utils.CheckPhone() ? 1 : ipad ? 3 : 2}
        spaceBetween={20}
        isEnableLoop={true}
        isCenteredSlides={true}
        isEnableNavButton={false}
        isEnablePagination={true}
        moveEffect={{
          moveFn: mainEffectFn,
        }}
      />
    </Paper>
  );
};

export default Testimony;
