import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
  Pagination,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import {
  Card,
  CardCover,
  CardContent,
  CardOverflow,
  AspectRatio,
} from "@mui/joy";
import { Box } from "@mui/system";
import { theme } from "./../theme";
import icon from "./../assets/AppIcon.png";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/id.json";

TimeAgo.addDefaultLocale(en);

const VideoBox = (props) => {
  const timeAgo = new TimeAgo("en-US");
  return (
    <>
      <Card
        sx={{ widht: "100%" }}
      // onClick={() => {
      //   window.open(props.video);
      // }}
      >
        <CardOverflow borderRadius={10} sx={{ position: "relative" }}>
          <AspectRatio ratio="2">
            <img src={props.poster} loading="lazy" alt="" />
          </AspectRatio>
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              verticalAlign: "middle",
              height: "100%",
              width: "90%",
            }}
          >
            <PlayCircleOutlineOutlinedIcon
              color="#f3e5f5"
              sx={{
                // display: "flex",
                position: "absolute",
                justifyContent: "center",
                alignContent: "center",
              }}
            />
          </div>
          {/* <video autoPlay loop muted poster={props.poster}>
            <source src={props.video} type="video/mp4" />
          </video> */}
        </CardOverflow>
        <CardContent>
          <Stack direction={"row"} spacing={2}>
            <Box
              component="img"
              src={icon}
              sx={{
                borderRadius: 10,
                width: 50,
                height: 50,
                objectFit: "cover",
              }}
            ></Box>
            <Stack>
              <Typography
                display="flex"
                variant="h6"
                color={"black"}
                sx={{
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  wordWrap: "break-word",
                  width: { xs: 220, md: 100, lg: 200 },
                  height: 60,
                }}
              >
                <b>{props.title}</b>
              </Typography>
              <Typography
                display="flex"
                level="body-xs"
                variant="subtitle2"
                color={"black"}
              >
                {timeAgo.format(new Date(props.date))}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MoreVideo = () => {
  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getVideos(value);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = (src) => {
    setOpen(true);
    setVideo(src);
  };
  const handleClose = () => setOpen(false);

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  const getVideos = (page) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_BE_API +
      "v1/get-videos?page=" +
      page +
      "&limit=12&orderDate=desc",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log("URL", "www.youtube.com/embed/" + getId(JSON.parse(result).results[0].url))
        setVideos(JSON.parse(result).results);
        setTotalPage(JSON.parse(result).totalPage);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getVideos(1);
  }, []);

  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <Grid container rowSpacing={3} columnSpacing={3} p={4}>
          {videos.map((row) => {
            return (
              <Grid
                item
                sm={4}
                md={3}
                width={"100%"}
                onClick={() => handleOpen("https://www.youtube.com/embed/" + getId(row.url))}
              >
                <VideoBox
                  poster={row.clickbait}
                  video={"www.youtube.com/embed/" + getId(row.url)}
                  title={row.title}
                  date={row.updated_at}
                />
              </Grid>
            );
          })}
        </Grid>

        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChange}
          alignContent="center"
          defaultPage={1}
        />
      </Stack>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box sx={style}>
            <iframe
              className="iframeVideo"
              width="100%"
              height="100%"
              src={video}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
export default MoreVideo;
