import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";

export function CheckPhone() {
  var check = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return check;
}
