import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Main from "./components/Main";
import Product from "./components/Product";
import About from "./components/About";
import Advantages from "./components/Advantages";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  useMediaQuery,
} from "@mui/material";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import MoreArticle from "./components/MoreArticle";
import ArticleDetail from "./components/ArticleDetail";
import MoreVideo from "./components/MoreVideo";

function App() {
  return (
    <>
      <Navbar />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      ></meta>
      <Routes>
        <Route path="/" element={<Main />}>
          {/* <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} /> */}

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
        <Route path="product" element={<Product />} />
        <Route path="about" element={<About />} />
        <Route path="advantages" element={<Advantages />} />
        <Route path="videos" element={<MoreVideo />} />
        <Route path="articles" element={<MoreArticle />} />
        <Route
          path="/article_detail/:id/:title"
          element={<ArticleDetail />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
