import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
  Pagination,
} from "@mui/material";
import { Card, CardCover, CardContent } from "@mui/joy";
import { Box } from "@mui/system";
import { theme } from "./../theme";
import { useNavigate } from "react-router-dom";

const ArticleBox = (props) => {
  return (
    <Paper
      sx={{ bgcolor: "background.transparent", width: "100%" }}
      variant="elevation"
      elevation={0}
      className="pointer"
    >
      <Stack>
        <Box
          component="img"
          src={props.image}
          sx={{
            // width: { xs: "10%", lg: "100%" },
            borderRadius: 10,
            width: "100%",
            height: 200,
            objectFit: "cover",
          }}
        />
        <Stack direction={"row"} spacing={2}>
          <Typography
            display="flex"
            level="body-xs"
            variant="subtitle2"
            color={"black"}
          >
            {props.author}
          </Typography>
          <li style={{ fontSize: "14px", color: "white" }}>{props.time}</li>
        </Stack>
        <Typography
          variant="h5"
          color={"black"}
          sx={{
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          <b>{props.title}</b>
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            textAlign: "left",
          }}
          level="body-xs"
          variant="subtitle2"
          color={"black"}
        >
          {props.preview}
        </Typography>
      </Stack>
    </Paper>
  );
};

const MoreArticle = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [totalPage, setTotalPage] = useState();
  useEffect(() => {
    getArticles(1);
  }, []);
  function RemoveHTMLTags(html) {
    var regX = /(<([^>]+)>)/gi;
    return html.replace(regX, "");
  }
  const getArticles = (page) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_BE_API +
        "v1/get-all-article?page=" +
        page +
        "&limit=12&orderDate=desc",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setArticle(JSON.parse(result).results);
        setTotalPage(JSON.parse(result).totalPage);
      })
      .catch((error) => console.log("error", error));
  };
  const [page, setPage] = React.useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getArticles(value);
  };
  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <Grid container rowSpacing={3} columnSpacing={3} p={4}>
          {article.map((row) => {
            return (
              <Grid
                item
                sm={4}
                md={3}
                onClick={() => {
                  navigate(
                    `/article_detail/${row.article_id}/${row.article_title}`,
                    {
                      state: {
                        title: row.article_title,
                        image: row.linkMinio,
                        preview: row.article_content,
                        author: row.author,
                        time: row.created_at,
                      },
                    }
                  );
                }}
              >
                <ArticleBox
                  image={row.linkMinio}
                  title={row.article_title}
                  preview={RemoveHTMLTags(row.article_content)}
                  author={row.author}
                  time={new Date(row.created_at).toDateString()}
                />
              </Grid>
            );
          })}
        </Grid>
        <Pagination
          count={totalPage}
          page={page}
          onChange={handleChange}
          alignContent="center"
          defaultPage={1}
        />
      </Stack>
    </>
  );
};

export default MoreArticle;
