import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";

import content3 from "./../../assets/phone.webp";

const Fingerprint = () => {
  return (
    <Paper sx={{ bgcolor: "background.darkBlue" }} variant="elevation" className="noRadius">
      <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" p={4}>
        <Box
          // style={{padding: '10%'}}
          component="img"
          src={content3}
          sx={{
            width: { xs: "100%", sm: "40%" },
            borderRadius: 10,
            // ml: { xs: 0, sm: -5 },
          }}
          m={5}
        />
        <Stack
          // sx={{ ml: 1, mr: { xs: 1, sm: 10 }, px: 4, pb: 4 }}
          // my={"auto"}
          // spacing={3}
          ml={1}
          mr={{ xs: 1, sm: 10 }}
          alignItems={"flex-start"}
          my={"auto"}
          spacing={3}
          className="noMargin textkontenSPM"
        >
          <Typography 
            display="flex"
            variant="h4"
            color="white"
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
            className="fontW700 fontJakarta"
          >
            Gunakan Aplikasi Future Link untuk melakukan test sidik jari
          </Typography>
          <Typography
            display="flex"
            justifyContent="center"
            variant="body1"
            color="white"
            sx={{
              textAlign: {
                xs: "center",
                sm: "left",
              },
            }}
            className="fontS18 fontJakarta"
          >
            Kemudahan yang kami tawarkan adalah efisiensi. Hanya dengan
            menggunakan aplikasi future link, kamu dapat mengetahui nilai
            potensi, bakat dan tipe kepribadianmu. Kamu bisa lakukan kapan saja
            dan dimana saja!
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Fingerprint;
