import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";
import MainImage from "./../../assets/MainImage";
import homeMain from "./../../assets/homeMain.png";
import appstore from "./../../assets/appstore.svg";
import playstore from "./../../assets/playstore.svg";
import text from "./../../assets/text1.webp";
import person from "./../../assets/person1.webp";

function MainHead() {
  if (!utils.CheckPhone()) {
    return (
      <>
        <Box component="img" src={homeMain} width={"100%"} px={2} />
        <Stack direction="row" spacing={4} justifyContent="center" style={{ marginTop: '50px' }}>
          <Stack>
            <Typography variant="h1" color="white" className="fontS32 fontW700 fontJakarta">
              999+
            </Typography>
            <Typography
              className="fontJakarta"
              display="flex"
              justifyContent="center"
              variant="body2"
              color="white"
              sx={{
                fontSize: {
                  xs: "1.0rem",
                  sm: "1.0rem",
                  md: "1.2rem",
                },
              }}
            >
              Views
            </Typography>
          </Stack>
          <Stack>
            <Typography display="flex" variant="h1" color="white" className="fontS32 fontW700 fontJakarta">
              360
            </Typography>
            <Typography
              display="flex"
              justifyContent="center"
              variant="body2"
              sx={{
                fontSize: {
                  xs: "1.0rem",
                  sm: "1.0rem",
                  md: "1.2rem",
                },
              }}
              color="white"
              className="fontJakarta"
            >
              Client
            </Typography>
          </Stack>
          <Stack>
            <Typography display="flex" variant="h1" color="white" className="fontS32 fontW700 fontJakarta">
              10+
            </Typography>
            <Typography
              display="flex"
              justifyContent="center"
              variant="body2"
              sx={{
                fontSize: {
                  xs: "1.0rem",
                  sm: "1.0rem",
                  md: "1.2rem",
                },
              }}
              color="white"
              className="fontJakarta"
            >
              Partner
            </Typography>
          </Stack>
        </Stack>
      </>
    );
  } else {
    return (
      <Stack alignContent="center" className="uwu">
        <Stack direction="row" justifyContent="center" mx={10} mb={-5}>
          <Box
            component="img"
            src={person}
            sx={{ width: { xs: "50%", md: "40%", lg: "40%" } }}
          />
          <Stack justifyContent="center" alignItems="flex-end">
            <Box component="img" src={text} sx={{ width: "90%" }} />
            <div style={{ marginTop: '15%' }}>
              <Typography
                className="noMargin fontJakarta"
                display="flex"
                justifyContent="flex-start"
                variant="body2"
                sx={{
                  mt: 5,
                  mr: { xs: 10, sm: 15, md: 5 },
                  fontSize: {
                    xs: "0.5rem",
                    sm: "1.0rem",
                    md: "1.2rem",
                  },
                }}
              >
                Available on:
              </Typography>
              <Stack direction="row" justifyContent="flex-start" spacing={2} style={{ marginTop: '24px' }}>
                <Box
                  className="pointer"
                  component="img"
                  src={appstore}
                  sx={{ width: { xs: "35%", lg: "35%" } }}
                  onClick={() =>
                    window.open(
                      " https://apps.apple.com/id/app/future-link-id/id6468541285"
                    )
                  }
                />
                <Box
                  className="pointer"
                  component="img"
                  src={playstore}
                  sx={{ width: { xs: "35%", lg: "35%" } }}
                  onClick={() =>
                    window.open(
                      " https://play.google.com/store/apps/details?id=com.infidea.flreport.prod "
                    )
                  }
                />
              </Stack>
            </div>

          </Stack>
        </Stack>
        {/* <Box component="img" src={count} sx={{ width: "80%" }} m="auto" mt={-7} /> */}
        <Container align="center">
          <MainImage views="999+" client="360" partner="10+" />
        </Container>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{
            mr: { xs: 5, sm: 15, md: 17, lg: 30, xl: 32 },
            mt: { xs: -10, sm: -12, md: -16, lg: -22, xl: -25 },
          }}
        ></Stack>
      </Stack>
    );
  }
}

export default MainHead;
