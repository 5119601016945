import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
  Breadcrumbs,
  Link,
  stepClasses,
} from "@mui/material";
import { Card, CardCover, CardContent } from "@mui/joy";
import { Box } from "@mui/system";
import { theme } from "./../theme";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
}

const ArticleDetail = () => {
  const { id, title } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // const path = location.pathname.split("/");
  const [st, setSt] = useState({});

  console.log(id);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_BE_API +
        "v1/get-article-by-id?articleId=" +
        id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const parse = JSON.parse(result).results[0];
        var state = {};
        state["title"] = parse.article_title;
        state["author"] = parse.author;
        state["image"] = parse.linkMinio;
        state["preview"] = String(parse.article_content);
        state["time"] = parse.created_at;
        setSt(state);
      })
      .catch((error) => console.log("error", error));
    // if (location.state == null) {
    // } else {
    //   setSt(location.state);
    // }
  }, []);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        navigate("/articles");
      }}
      className="pointer"
    >
      List Artikel
    </Link>,
    <Typography key="3" color="text.primary">
      Artikel
    </Typography>,
  ];

  return (
    <>
      <Paper
        sx={{ bgcolor: "background.transparent", width: "100%" }}
        variant="elevation"
        elevation={0}
      >
        <Stack p={4}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Typography
            variant="h5"
            color={"black"}
            sx={{
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            <b>{st.title}</b>
          </Typography>
          <Stack direction={"row"} spacing={2} mb={2}>
            <Typography
              display="flex"
              level="body-xs"
              variant="subtitle2"
              color={"black"}
            >
              {st.author}
            </Typography>
            <li style={{ fontSize: "14px", color: "white" }}>
              {new Date(st.time).toDateString()}
            </li>
          </Stack>
          <Box
            component="img"
            src={st.image}
            sx={{
              // width: { xs: "10%", lg: "100%" },
              borderRadius: 10,
              width: "100%",
              objectFit: "contain",
            }}
          />
          {st.preview == undefined ? "" : parse(st.preview)}
        </Stack>
      </Paper>
    </>
  );
};

export default ArticleDetail;
