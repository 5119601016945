import * as React from "react";
import { Typography, Menu, MenuItem, AppBar, Container,Link, Grid, Button, Stack, styled, Paper, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { Card, CardCover, CardContent, CardOverflow, AspectRatio} from "@mui/joy";
import { Box } from "@mui/system";
import b2b from "./../assets/B2B.svg";
import b2c from "./../assets/B2C.svg";
import backgound2 from "./../assets/background.webp";

const StyledPaper = styled(Paper)({
  backgroundImage: `url(${backgound2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});

const Advantages = () => {
  var phone = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  return (
    <>
      <a style={{width: 'max-content',
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      padding: '4px 24px',
      gap: '18px',
      fontSize: '14px',
      fontWeight: '600',
      backgroundColor: '#1F599C90',
      color: 'white',
      borderRadius: '18px',
      right: '32px',
      bottom: '32px',
      zIndex: '99999999',
      textDecoration: 'none'
      }} 
      href="https://wa.me/6285960206799"
      target="_blank"
      > 
        <div>
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1244_12395)">
          <path d="M0 48L3.31031 36C1.15866 32.2759 0 28.1379 0 23.8344C0 10.7587 10.7587 0 23.9172 0C37.0758 0 48 10.7587 48 23.8344C48 36.9102 37.0759 48 23.9172 48C19.7793 48 15.8069 46.6759 12.2482 44.6897L0 48Z" fill="white"/>
          <path d="M12.9091 40.5676L13.6539 40.9814C16.716 42.8848 20.2747 44.0435 23.9988 44.0435C34.8401 44.0435 43.8608 35.0227 43.8608 24.0158C43.8608 13.0089 34.8401 4.31934 23.916 4.31934C12.9919 4.31934 4.13672 13.0917 4.13672 24.0158C4.13672 27.8227 5.21259 31.5469 7.19878 34.6917L7.69537 35.4366L5.79197 42.3883L12.9091 40.5676Z" fill="#55CD6C"/>
          <path d="M17.5447 12.5633L15.9723 12.4805C15.4757 12.4805 14.9792 12.646 14.6482 12.9771C13.9034 13.6391 12.662 14.8805 12.331 16.5357C11.7517 19.0185 12.662 21.9978 14.8138 24.9771C16.9655 27.9563 21.1035 32.7563 28.3861 34.8253C30.7034 35.4874 32.5241 35.0736 34.0138 34.1633C35.1724 33.4184 35.9172 32.2598 36.1655 31.0184L36.4138 29.8598C36.4965 29.5287 36.331 29.1149 36 28.9494L30.7861 26.5494C30.4551 26.3839 30.0413 26.4667 29.793 26.7977L27.7241 29.4459C27.5585 29.6115 27.3103 29.6942 27.062 29.6115C25.6551 29.1149 20.9379 27.1287 18.3723 22.1633C18.2895 21.915 18.2895 21.6667 18.4551 21.5012L20.4413 19.2668C20.6069 19.0185 20.6895 18.6875 20.6069 18.4391L18.2069 13.0598C18.124 12.8115 17.8757 12.5633 17.5447 12.5633Z" fill="#FEFEFE"/>
          </g>
          <defs>
          <clipPath id="clip0_1244_12395">
          <rect width="48" height="48" fill="white"/>
          </clipPath>
          </defs>
        </svg>

        </div>
        <div>
          <p>Butuh Bantuan? <br/>Hubungi Kami</p>
        </div>
      </a>
      <StyledPaper
        elevation={0}
        sx={{
          height: { xs: 1200, sm: 800, md: 700, lg: 800, xl: 1000 },
          mt: -15,
          pt: 15,
        }}
      >
        <Stack direction={!phone ? "column" : "row"} py={5} style={{justifyContent:'center'}} >
          <Stack alignItems={"center"} spacing={4} style={{padding: '24px'}}>
            <Typography
              display="flex"
              variant="h4"
              color="#374151"
              className= "fontJakarta"
            >
              <strong>B2C</strong>
            </Typography>
            <Box component="img" src={b2c} style={{width: '40%'}} />
            <Typography
              display="flex"
              variant="body2"
              color="#374151"
            >
              <ul style={{padding: '0px 10%', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <li className="noStyle fontJakarta">
                  🔍 <b>Pemahaman Diri:</b> Temukan potensi tersembunyi dan
                  pahami diri Anda lebih baik.
                </li>
                <li className="noStyle fontJakarta">
                  💡 <b>Panduan Karier:</b> Dapatkan wawasan tentang jalur
                  karier yang sesuai dengan minat dan bakat Anda.
                </li>
                <li className="noStyle fontJakarta">
                  🚀 <strong>Pengembangan Pribadi:</strong> Rencanakan
                  pertumbuhan pribadi dengan informasi yang mendalam tentang
                  diri Anda.
                </li>
              </ul>
            </Typography>
          </Stack>
          <Stack alignItems={"center"} spacing={4} style={{padding: '24px'}}>
            <Typography
              display="flex"
              variant="h4"
              color="#374151"
              justifyContent={"flexStart"}
              className= "fontJakarta"
            >
              <strong>B2B</strong>
            </Typography>
            <Box component="img" src={b2b} style={{width: '40%'}} />
            <Typography
              display="flex"
              variant="body2"
              color="#374151"
              justifyContent={"flexStart"}
            >
              <ul style={{padding: '0px 10%', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <li className="noStyle fontJakarta">
                  ✅ <b>Optimalkan Rekrutmen:</b> Pilih kandidat yang sesuai
                  dengan nilai dan budaya perusahaan Anda.
                </li>
                <li className="noStyle fontJakarta">
                  ✅ <b> Pengembangan Tim:</b> Identifikasi potensi karyawan
                  untuk pengembangan karier yang berkelanjutan.
                </li>
                <li className="noStyle fontJakarta">
                  ✅ <b>Penilaian Kinerja:</b> Evaluasi dan tingkatkan kinerja
                  tim dengan pemahaman mendalam tentang setiap individu.
                </li>
              </ul>
            </Typography>
          </Stack>
        </Stack>
      </StyledPaper>
    </>
  );
};

export default Advantages;
