import {
  createTheme,
  experimental_extendTheme as extendTheme,
} from "@mui/material";
import "@fontsource/plus-jakarta-sans/300.css";
import "@fontsource/plus-jakarta-sans/400.css";
import "@fontsource/plus-jakarta-sans/500.css";
import "@fontsource/plus-jakarta-sans/700.css";

export const theme = extendTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
    // MuiCssBaseline: {
    //   styleOverrides: `@font-face {
    //       font-family: 'Satoshi-Regular';
    //       src: url('../fonts/Satoshi-Regular.woff2') format('woff2'),
    //            url('../fonts/Satoshi-Regular.woff') format('woff'),
    //            url('../fonts/Satoshi-Regular.ttf') format('truetype');
    //            font-weight: 700;
    //            font-display: swap;
    //            font-style: normal;
    //     }
    //     `,
    // },
  },
  palette: {
    background: {
      darkBlue: "#1F599C",
      lightBlue: "#356BA9",
      transparent: "00FFFFFF",
    },
    orange: "#FF7518",
  },
});
