import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import * as utils from "./../Utils";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// const BearCarousel = styled.div`
//   .${elClassName.paginationGroup} {
//     buttom: 0px;
//   }
// `;
// const TextAnimationsRoot = styled.div`
//   --primary-color: #c4a265;

//   .${elClassName.paginationGroup} {
//     }

//     :before {
//       bottom:10px
//     }
//   }
// `;

const ArticleBox = (props) => {
  return (
    <Paper
      sx={{ bgcolor: "background.transparent", width: "100%" }}
      variant="elevation"
      elevation={0}
    >
      <Stack className="pointer">
        <Box
          component="img"
          src={props.image}
          sx={{
            // width: { xs: "10%", lg: "100%" },
            borderRadius: 10,
            width: "100%",
            height: 200,
            objectFit: "cover",
          }}
        />
        <Stack direction={"row"} spacing={2} style={{ marginTop: "12px" }}>
          <Typography
            display="flex"
            level="body-xs"
            variant="subtitle2"
            color={"white"}
            style={{ fontSize: "12px" }}
            className="fontJakarta"
          >
            {props.author}
          </Typography>
          <Typography color={"white"}>
            <li style={{ fontSize: "12px" }} className="fontJakarta">
              {props.time}{" "}
            </li>
          </Typography>
        </Stack>
        <Typography
          variant="h5"
          color={"white"}
          sx={{
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          <b className="fontJakarta fontW700 fontS18">{props.title}</b>
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            textAlign: "left",
          }}
          level="body-xs"
          variant="subtitle2"
          color={"white"}
          className="fontJakarta fontW400"
        >
          {props.preview}
        </Typography>
      </Stack>
    </Paper>
  );
};

const Article = () => {
  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_BE_API +
        "v1/get-all-article?page=0&limit=5&orderDate=desc",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => setArticle(JSON.parse(result).results))
      .catch((error) => console.log("error", error));
  }, []);
  const [article, setArticle] = useState([]);
  function RemoveHTMLTags(html) {
    var regX = /(<([^>]+)>)/gi;
    return html.replace(regX, "");
  }

  const bearSlideItemData: TBearSlideItemDataList = article.map((row) => {
    return {
      key: row.id,
      children: (
        <BearSlideCard
          onClick={() => {
            navigate(`/article_detail/${row.article_id}/${row.article_title}`, {
              state: {
                title: row.article_title,
                image: row.linkMinio,
                preview: row.article_content,
                author: row.author,
                time: row.created_at,
              },
            });
          }}
        >
          <ArticleBox
            image={row.linkMinio}
            title={row.article_title}
            preview={RemoveHTMLTags(row.article_content)}
            author={row.author}
            time={new Date(row.created_at).toDateString()}
          />
        </BearSlideCard>
      ),
    };
  });
  const ipad = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const xl = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        bgcolor: "background.darkBlue",
        borderRadius: 0,
        height: 1000,
        py: 5,
      }}
      variant="plain"
      align="center"
    >
      <Card
        sx={{ width: "90%", height: "100%", borderRadius: "40px" }}
        variant="soft"
        className="cleanbackground"
      >
        <CardCover
          sx={{
            background: `linear-gradient(#356BA9, #195295)`,
            backdropFilter: "blur(1px)",
            py: 3,
          }}
        >
          <CardContent>
            <Stack spacing={2} width="90%" height="100%">
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent="flex-end"
                onClick={() => {
                  navigate("/videos");
                }}
                className="pointer"
              >
                <Typography
                  display="flex"
                  variant="body3"
                  sx={{ mt: 5, mr: 12 }}
                  color={"white"}
                  className="fontJakarta"
                >
                  View More
                </Typography>
                {/* <ArrowForwardIcon sx={{ color: "white" }} /> */}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="24" height="24" rx="12" fill="white" />
                  <g clip-path="url(#clip0_4138_3980)">
                    <path
                      d="M17.8082 11.5339L13.4746 7.20015C13.3509 7.07645 13.186 7.00854 13.0102 7.00854C12.8342 7.00854 12.6694 7.07654 12.5457 7.20015L12.1522 7.59371C12.0286 7.71722 11.9605 7.8822 11.9605 8.0581C11.9605 8.2339 12.0286 8.40444 12.1522 8.52795L14.6804 11.0617H6.64829C6.28614 11.0617 6 11.3452 6 11.7074V12.2638C6 12.6261 6.28614 12.9382 6.64829 12.9382H14.7091L12.1523 15.486C12.0287 15.6097 11.9606 15.7702 11.9606 15.9461C11.9606 16.1218 12.0287 16.2847 12.1523 16.4083L12.5458 16.8006C12.6695 16.9243 12.8343 16.9917 13.0103 16.9917C13.1861 16.9917 13.351 16.9234 13.4747 16.7997L17.8083 12.4661C17.9323 12.342 18.0005 12.1763 18 12.0002C18.0004 11.8235 17.9323 11.6578 17.8082 11.5339Z"
                      fill="#1F599C"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4138_3980">
                      <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(6 6)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Stack>
              <iframe
                className="iframeVideo"
                width="100%"
                height="1100"
                src="https://www.youtube.com/embed/7FfMfAiI-Mo?si=11hjy5KvSnLvICZ4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <Stack direction={"row"} justifyContent="space-between">
                <Typography
                  display="flex"
                  variant="h5"
                  color={"white"}
                  style={{ fontWeight: "700" }}
                >
                  Artikel
                </Typography>
                <Stack
                  className="pointer"
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  onClick={() => {
                    navigate("/articles");
                  }}
                >
                  <Typography display="flex" variant="body3" color={"white"}>
                    View More
                  </Typography>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="12" fill="white" />
                    <g clip-path="url(#clip0_4138_3980)">
                      <path
                        d="M17.8082 11.5339L13.4746 7.20015C13.3509 7.07645 13.186 7.00854 13.0102 7.00854C12.8342 7.00854 12.6694 7.07654 12.5457 7.20015L12.1522 7.59371C12.0286 7.71722 11.9605 7.8822 11.9605 8.0581C11.9605 8.2339 12.0286 8.40444 12.1522 8.52795L14.6804 11.0617H6.64829C6.28614 11.0617 6 11.3452 6 11.7074V12.2638C6 12.6261 6.28614 12.9382 6.64829 12.9382H14.7091L12.1523 15.486C12.0287 15.6097 11.9606 15.7702 11.9606 15.9461C11.9606 16.1218 12.0287 16.2847 12.1523 16.4083L12.5458 16.8006C12.6695 16.9243 12.8343 16.9917 13.0103 16.9917C13.1861 16.9917 13.351 16.9234 13.4747 16.7997L17.8083 12.4661C17.9323 12.342 18.0005 12.1763 18 12.0002C18.0004 11.8235 17.9323 11.6578 17.8082 11.5339Z"
                        fill="#1F599C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_4138_3980">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(6 6)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </Stack>
              </Stack>
              <BearCarousel
                data={bearSlideItemData}
                slidesPerView={
                  !utils.CheckPhone() ? 1 : ipad ? (xl ? 4 : 3) : 2
                }
                spaceBetween={20}
                isEnablePagination
                isEnableAutoPlay
                height={90}
              />
              {/* <TextAnimationsRoot /> */}
              {/* <Stack direction={"row"} spacing={2}>
                  <ArticleBox image="https://i.ytimg.com/vi/yA3j7eIDwvw/maxresdefault.jpg" />
                  <ArticleBox image="https://i.ytimg.com/vi/xVWeRnStdSA/maxresdefault.jpg" />
                  <ArticleBox image="https://media.tenor.com/RX8qFki88iIAAAAd/huh-cat.gif" />
                </Stack> */}
            </Stack>
          </CardContent>
        </CardCover>
      </Card>
    </Paper>
  );
};

export default Article;
