import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Paper,
  styled,
  Stack,
  Typography,
  IconButton,
  Icon,
  Container,
  Grid,
  Button,
  TextField,
  Rating,
  Divider,
  useMediaQuery,
  CircularProgress,
  Hidden,
} from "@mui/material";
import { Box } from "@mui/system";
import { Card, CardCover, CardContent } from "@mui/joy";
import { theme } from "./../../theme";
import "bear-react-carousel/dist/index.css";
import BearCarousel, {
  TMoveEffectFn,
  TBearSlideItemDataList,
  BearSlideCard,
  elClassName,
  Controller,
  TOnSlideChange,
} from "bear-react-carousel";
import { useNavigate, redirect } from "react-router-dom";
import EmailIcon from "@mui/icons-material/EmailOutlined";

const Newsletter = () => {
  const valueRef = useRef("");
  const [show, setShow] = useState(false);

  const formdata = new FormData();
  const inputEmail = (email) => {
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_BE_API +
        "v1/add-email-newsletter?email=" +
        email,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log("success", JSON.parse(result));
        setShow(false);
        var res = JSON.parse(result);
        if (res.statusCode == 400) {
          alert(res.message);
        } else if (res.statusCode == 422) {
          alert(res.message);
        } else {
          alert("Thank you for join us!");
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <Stack alignItems={"center"} spacing={2} my={5} px={5}>
      <Typography variant="body1" className="colorText fontJakarta">
        <strong>Newsletter</strong>
      </Typography>
      <Typography variant="body2" className="colorTextLight fontJakarta">
        Jadilah orang pertama yang mendapat penawaran dari Future Link!
      </Typography>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        justifyContent={"center"}
      >
        <EmailIcon sx={{ color: "action.active" }} />
        <TextField
          label="Masukkan Emailmu"
          variant="outlined"
          sx={{ m: 1, width: "100%" }}
          inputRef={valueRef}
        />
        {/* {show && <CircularProgress />} */}
        <Button
          variant="contained"
          sx={{
            borderRadius: 50,
            textTransform: "none",
          }}
          onClick={() => {
            setShow(true);
            inputEmail(valueRef.current.value);
          }}
          style={{ width: "200px", backgroundColor: "#1F599C" }}
        >
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

export default Newsletter;
