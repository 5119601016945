import * as React from "react";
import {
  Typography,
  Menu,
  MenuItem,
  AppBar,
  Container,
  Link,
  Grid,
  Button,
  Stack,
  styled,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import logo from "./../assets/logo_with_name.png";
import burgerBar from "./../assets/burgerBar.svg";

const StyledButton = styled(Button)({
  borderRadius: 50,
  textTransform: "none",
  padding: "2px 30px 2px 30px",
});

const Navbar = () => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Stack justifyContent="space-between" direction={"row"} p={2}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Box component="img" src={logo} sx={{ width: "40%" }}></Box>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Box
              component="img"
              src={burgerBar}
              sx={{ width: "20%" }}
              onClick={() => {
                !state ? setState(true) : setState(false);
              }}
            ></Box>
          </Grid>
        </Stack>
        {/* <ListItem disablePadding sx={{ bgcolor: "background.lightBlue" }}>
          <ListItemButton>
            <ListItemText
              align="center"
              primary={
                <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                  <b>Masuk</b>
                </Typography>
              }
              onClick={() => {
                window.open(process.env.REACT_APP_BASE_API);
              }}
            />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding sx={{ bgcolor: "background.lightBlue" }}>
          <ListItemButton>
            <ListItemText
              align="center"
              primary={
                <Typography variant="body1" style={{ color: "#FFFFFF" }}>
                  <b>Buat Akun</b>
                </Typography>
              }
              onClick={() => {
                window.open(process.env.REACT_APP_BASE_API + "register");
              }}
            />
          </ListItemButton>
        </ListItem>
        {pages.map((page) => (
          <ListItem key={page.name}>
            <ListItemButton>
              <ListItemText
                align="center"
                primary={<b>{page.name}</b>}
                onClick={() => {
                  navigate(page.link);
                  setState(false);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const pages = [
    { link: "/", name: "Halaman Utama" },
    { link: "/product", name: "Produk" },
    { link: "/about", name: "Tentang Kami" },
    { link: "/advantages", name: "Keuntungan" },
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigate = useNavigate();
  const phone = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  if (!phone) {
    return (
      <>
        <Stack justifyContent="space-between" direction={"row"} p={2}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Box
              component="img"
              src={logo}
              sx={{ width: "40%" }}
              onClick={() => navigate("/")}
            ></Box>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Box
              component="img"
              src={burgerBar}
              sx={{ width: "20%" }}
              onClick={() => {
                state ? setState(false) : setState(true);
              }}
            ></Box>
          </Grid>
        </Stack>
        <Drawer anchor={"top"} open={state}>
          {list("top")}
        </Drawer>
      </>
    );
  } else {
    return (
<>
        {/* <AppBar> */}
        <Stack direction="row" spacing={1} style={{padding: '24px'}}>
          <Grid container justifyContent="flex-start" alignItems="center" style={{flex: '1'}}>
            <Box
              component="img"
              src={logo}
              sx={{ width: "30%" }}
              onClick={() => navigate("/")}
            ></Box>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="center" style={{flex: '2'}}>
            <Stack direction={"row"}>
              {pages.map((page) => (
                <Link
                  noWrap
                  className="navLink fontJakarta fontColorHover"
                  sx={{ ml: 10 }}
                  component="button"
                  variant="body2"
                  underline="hover"
                  // color="black"
                  onClick={() => {
                    navigate(page.link);
                  }}
                >
                  {page.name}
                </Link>
              ))}
            </Stack>
          </Grid>
          {/* <Grid container justifyContent="flex-end" alignItems="flex-end">
            <StyledButton
              size="small"
              variant="contained"
              sx={{ mr: 1 }}
              onClick={() => {
                window.open(process.env.REACT_APP_BASE_API);
              }}
            >
              Masuk
            </StyledButton>
            <StyledButton
              size="small"
              variant="contained"
              onClick={() => {
                window.open(process.env.REACT_APP_BASE_API + "register");
              }}
            >
              Buat Akun
            </StyledButton>
          </Grid> */}
        </Stack>
        {/* </AppBar> */}
</>
    );
  }
};

export default Navbar;
